<template>
  <div v-if="!isFetching && campaignData">
    <keep-alive>
      <component
        :is="pageComponent"
        :showErrorMessage="showErrorMessage"
      ></component>
    </keep-alive>
  </div>
  <div v-else class="spin">
    <a-spin />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import {
  getIP,
  getOS,
  getDevice,
  getBrowser,
} from "@/services/helperFunctions";
import { API } from "@/services/api";
import { defineAsyncComponent, shallowRef } from "vue";
import Notification from "@/services/Notification";

export default {
  name: "CampaignDetail",
  computed: {
    pageCount: function () {
      return this.$store.getters.pageCount;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
  },
  data() {
    return {
      ip: "",
      os: getOS(),
      setMeta: null,
      countryCode: "",
      isFetching: true,
      removeMeta: null,
      pageComponent: null,
      device: getDevice(),
      browser: getBrowser(),
      showErrorMessage: false,
    };
  },
  metaInfo() {
    return {
      title: this.campaignData.meta_title,
      meta: [
        {
          name: "title",
          content: this.campaignData.meta_title,
          vmid: "title",
        },
        {
          name: "description",
          content: this.campaignData.meta_description,
          vmid: "description",
        },
        {
          property: "og:title",
          content: this.campaignData.meta_title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.campaignData.meta_description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: this.campaignData.meta_image,
          vmid: "og:image",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getCampaignData", "getCountries"]),
    async callCampaignView() {
      try {
        const fData = new FormData();
        fData.append("os", this.os);
        fData.append("ip", this.ip);
        fData.append("device", this.device);
        fData.append("browser", this.browser);
        fData.append("slug", this.$route.params.slug);
        await API.callVisitor(fData);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCampaignDetails() {
      console.log(this.$route.params.slug);
      try {
        const dataRes = await this.getCampaignData({
          slug: this.$route.params.slug,
        });
        const { cdata, status } = dataRes;
        if (!status || _.isEmpty(cdata)) {
          this.$router.replace({ path: "/not-found" }).catch(() => {});
          return null;
        }
        if (status) {
          this.pageComponent = shallowRef(
            defineAsyncComponent(() =>
              import(
                `@/view/frontend/campaign/${this.campaignData.game_slug}/${this.campaignData.theme_slug}/Page${this.pageCount}.vue`
              )
            )
          );
          await this.checkCampaignConditions();
          this.isFetching = false;
          this.setScriptAndMeta();
          this.addCustomCss();
        }
      } catch (error) {
        this.isFetching = false;
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
        this.showErrorMessage = true;
      }
    },
    async updateData() {
      this.os = getOS();
      this.device = getDevice();
      this.browser = getBrowser();
      return new Promise((resolve, reject) => {
        getIP()
          .then((ipAddress) => {
            this.ip = ipAddress.ip;
            this.countryCode = ipAddress.countryCode;
            resolve(true);
          })
          .catch((err) => reject(err));
      });
    },
    checkCampaignConditions() {
      return new Promise((resolve, reject) => {
        const {
          browser = [],
          os = [],
          country = [],
          device = [],
          not_found_url = "",
          is_expired,
          not_found,
        } = this.campaignData;
        try {
          const hasOs =
            _.findIndex(os, (o) => o.toLowerCase() == this.os.toLowerCase()) >
              -1 || os.length == 0;
          const hasBrowser =
            _.findIndex(
              browser,
              (b) => b.toLowerCase() == this.browser.toLowerCase()
            ) > -1 || browser.length == 0;
          const hasDevice =
            _.findIndex(
              device,
              (d) => d.toLowerCase() == this.device.toLowerCase()
            ) > -1 || device.length == 0;
          const hasCountry =
            _.findIndex(country, (c) => c == this.countryCode) > -1 ||
            country.length == 0;

          if (
            is_expired ||
            !hasOs ||
            !hasBrowser ||
            !hasDevice ||
            !hasCountry
          ) {
            if (not_found == 1 && not_found_url) {
              location.href = not_found_url;
            }
            this.showErrorMessage = true;
            resolve(true);
          } else {
            // this.callCampaignView();
            resolve(true);
          }
        } catch (error) {
          if (not_found_url) {
            location.href = not_found_url;
          }
          this.showErrorMessage = true;
          reject(true);
        }
      });
    },
    setScriptAndMeta() {
      const {
        scripts = [],
        meta_title = "",
        meta_image = "",
        meta_description = "",
      } = this.campaignData;
      if (scripts.length > 0) {
        for (let scrp of scripts) {
          const parser = new DOMParser().parseFromString(
            scrp.script,
            "text/html"
          );
          const scriptTag = parser.querySelector("script");
          const newScriptTag = document.createElement("script");
          [...scriptTag.attributes].forEach((a) => {
            newScriptTag.setAttribute(a.nodeName, a.nodeValue);
          });
          if (scrp.position == 1) {
            document.head.appendChild(newScriptTag);
          } else if (scrp.position == 2) {
            document.body.appendChild(newScriptTag);
          } else if (scrp.position == 3) {
            document.body.appendChild(newScriptTag);
          }
        }
      }
      if (meta_title) {
        document.title = meta_title;
        const metaTitleEl = document.querySelector('head meta[name="title"]');
        const metaOgTitleEl = document.querySelector(
          'head meta[name="og:title"]'
        );
        metaTitleEl.setAttribute("content", meta_title);
        if (metaOgTitleEl) {
          metaOgTitleEl.setAttribute("property", "og:title");
          metaOgTitleEl.setAttribute("content", meta_title);
        }
      }
      if (meta_description) {
        const metaDescEl = document.querySelector(
          'head meta[name="description"]'
        );
        const metaOgDescEl = document.querySelector(
          'head meta[name="og:description"]'
        );
        metaDescEl.setAttribute("content", meta_description);
        if (metaOgDescEl) {
          metaOgDescEl.setAttribute("property", "og:description");
          metaOgDescEl.setAttribute("content", meta_description);
        }
      }
      if (meta_image) {
        const metaOgImgEl = document.querySelector(
          'head meta[name="og:image"]'
        );
        if (!metaOgImgEl) {
          const metaOgImage = document.createElement("meta");
          metaOgImage.setAttribute("property", "og:image");
          metaOgImage.setAttribute("content", meta_image);
          document.head.append(metaOgImage);
        } else {
          metaOgImgEl.setAttribute("property", "og:image");
          metaOgImgEl.setAttribute("content", meta_image);
        }
      }
    },
    addCustomCss() {
      const { custom_css = "" } = this.campaignData;
      if (custom_css) {
        let style = document.createElement("style");
        style.innerHTML = custom_css;
        document.head.append(style);
      }
    },
  },
  async beforeMount() {
    this.$store.commit("setWinnerFlag", false);
    this.$store.commit("manageSubscribeFlag", false);
    this.$store.commit("managePageVisit", false);
    this.$store.commit("setPageCount", 1);
    try {
      await this.updateData();
      this.getCampaignDetails();
    } catch (error) {
      this.showErrorMessage = true;
    }
  },
  created() {
    this.getCountries();
  },
};
</script>